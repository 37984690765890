import { type CfgIssuesCardHeaderProps, type CfgIssuesCollateralsProps, type CfgIssuesInfoProps, type CfgIssuesProgressProps, collateralsIconMap } from '@cfgtech/ui'
import dayjs from 'dayjs'
import { type Issue, type IssueList, PeriodicityEnum } from '~/services/generated/client/public'

export function useIssuesDataCommon(issue: Issue | IssueList) {
  const { t, n } = useI18n()
  const responsive = useResponsive()

  const isMobile = computed(() => {
    return responsive.value.mobile || responsive.value.tablet
  })

  const periodicityFraction = computed(() => {
    switch (issue.periodicity) {
      case PeriodicityEnum.Monthly:
        return 12
      case PeriodicityEnum.Quarterly:
        return 4
      default:
        return 1
    }
  })

  const collaterals = computed<CfgIssuesCollateralsProps>(() => ({
    collaterals: issue.collaterals
      .map(collateral => ({
        ...collateral,
        icon: collateralsIconMap[collateral.type as keyof typeof collateralsIconMap] || undefined,
        info: null,
        amountFormatted: collateral.amount
          ? t('issue.collaterals.amount', { amount: n(getSafeNumber(collateral.amount), 'currencyWithoutDecimals') })
          : null,
        possessiveLabel: collateral.type,
      })),
    label: t('issue.collaterals.label'),
    moreTypesLabel: t('issue.collaterals.moreTypes', { n: issue.collaterals.length }),
    moreLabel: t('issue.collaterals.more'),
    slideOverTitle: t('issue.collaterals.title'),
    tooltipButtonLabel: t('issue.collaterals.tooltipButton'),
    slideOverSubtitle: issue.projectName || issue.name,
    isMobile: isMobile.value,
    isPublic: true,
  }))

  const interestRateFormatted = computed(() => {
    if (!issue.interestRate)
      return ''
    const number = Number(issue.interestRate)
    if (Number.isNaN(number))
      return ''
    return n(number / 100, 'interestRate')
  })

  const progress = computed<CfgIssuesProgressProps>(() => ({
    amountLabel: t('issue.investedAmount'),
    amountValue: issue.investedAmount,
    amountValueFormatted: n(getSafeNumber(issue.investedAmount), 'currencyWithoutDecimals'),
    investorsCount: issue.investorsCount,
    investorsCountLabel: t('issue.investors', issue.investorsCount),
    remainingAmountValue: issue.remainingAmount,
    remainingAmountLabel: t('issue.remaining'),
    remainingAmountFormatted: n(getSafeNumber(issue.remainingAmount), 'currencyWithoutDecimals'),
  }))

  const monthsUntilMaturity = computed(() => t('issue.monthsUntilMaturity', {
    n: dayjs(issue.maturityDate).diff(dayjs(), 'month'),
  }))

  const info = computed<CfgIssuesInfoProps>(() => ({
    maturityDateLabel: t('issue.maturityDateValue'),
    maturityDateValue: monthsUntilMaturity.value,
    minimumInvestmentLabel: t('issue.minimumInvestment'),
    minimumInvestmentValue: n(getSafeNumber(issue.nominalValue), 'currencyWithoutDecimals'),
    periodicityLabel: t('issue.periodicityPaymentLabel'),
    periodicityValue: t(`issue.periodicity.${issue.periodicity}`),
    investorCountLabel: t('issue.investorCount'),
    investorCountValue: issue.investorsCount,
  }))

  const header = computed<CfgIssuesCardHeaderProps>(() => ({
    tags: issue.tags,
    rating: {
      rating: issue.rating,
      ratingLabel: t('issue.ratingChip'),
    },
    imageUrl: issue.heroSection?.image?.url,
  }))

  const maturityLabel = computed(() => t('issue.maturityLabel'))

  return {
    collaterals,
    periodicityFraction,
    interestRateFormatted,
    progress,
    info,
    header,
    monthsUntilMaturity,
    maturityLabel,
  }
}
